/*
 * @Author: 吴慧彬 wuhuibin@scooper.com.cn
 * @Date: 2022-03-15 17:28:05
 * @LastEditTime: 2024-07-30 17:29:43
 * @LastEditors: wangliang
 * @FilePath: \monitoring-web-ui\src\config\config.jsx
 */
export const systemNameTop = "微震监测数据接收系统";
export const systemNameContent = "微震监测数据接收系统";
export const systemNameBottom =
	`copyright©${new Date().getFullYear()} 深蓝感知（杭州）物联科技有限公司`;
export const defaultMenu = "/deviceManage";
export const menuList = [
	{ itemlist: "实时监测", path: "/realtimeMonitor" },
	{ itemlist: "微震分析", path: "/microAnalysis" },
	{ itemlist: "设备管理", path: "/deviceManage" },
	{ itemlist: "数据统计", path: "/dataStatistics" },
	{ itemlist: "系统设置", path: "/sysSetting" },
];

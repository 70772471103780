/*
 * @Author: 吴慧彬 wuhuibin@scooper.com.cn
 * @Date: 2022-03-15 16:46:38
 * @LastEditTime: 2023-08-29 09:52:17
 * @LastEditors: wangliang
 * @FilePath: \monitoring-web-ui\src\router\index.jsx
 */
import React, { useEffect, lazy, useRef } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { KeepAlive, AliveScope } from 'react-activation';
import Login from "../view/login";
import PageLoading from "../component/page-load";
import SysHeader from "../component/sys-header";
import { getToken } from "../config/constants";
import { systemNameBottom } from "../config/config";
import { micro, SOCKET_DEV_HOST } from "@/util/apis"
const RealtimeMonitor = lazy(() => import("../view/realtime-monitor"));
const MicroAnalysis = lazy(() => import("../view/micro-analysis"));
const DeviceManage = lazy(() => import("../view/device-manage"));
const DataStatistics = lazy(() => import("../view/data-statistics"));
const SysSetting = lazy(() => import("../view/sys-setting"));

const SafeSet = lazy(()=> import("../view/sys-setting/safe-set"));
const WaveFormSet = lazy(()=> import("../view/sys-setting/waveForm-set"));
const FilterSet = lazy(()=> import ("../view/sys-setting/filter-set"));
const AlgorithmSet = lazy(()=> import ("../view/sys-setting/algorithm-set"));
const PlatformAccess = lazy(()=> import ("../view/sys-setting/platform-access"));
const AccountManager = lazy(()=> import ("../view/sys-setting/account-manager"));

const AcquistionStation = lazy(()=> import ("../view/device-manage/acquisition-station"));
const Workspace = lazy(()=> import ("../view/device-manage/workspace"));
export default function Index() {
	const ws = useRef(null);
	const location = useLocation();
	const navigate = useNavigate();
	const { pathname } = location;

	let accId
    try {
        accId = JSON.parse(localStorage.getItem('userDetail')).id
    }catch {}

	// websocket心跳检测定时器id
	let timer = null
	let heartCheck = { 
        reset: function(){
            clearTimeout(timer);
        },
        start: function(){
			// 定时发送消息，防止断连
            timer = setInterval(function(){
                if(ws.current.readyState == 1){
                    ws.current.send("heart;;;");
                }else{
                }
            }, 50000)
        }
    }
	useEffect(() => {
		// 定位到login
		let token;
		try {
			token = getToken();
		} catch (e) {
			// alert("excaption" + e);
		}
		if (pathname === "/") {
			if (token === "undefined" || !token) {
				navigate("/login");
			} else {
				navigate("/deviceManage");
			}
		}
		if (pathname === "/login") {
			if (token === "undefined" || !token) {
			} else {
				navigate("/deviceManage");
			}
		}
		// 没token时跳转登录页
		if (pathname !== "/login" && pathname !== "/") {
			if (token === "undefined" || !token) return navigate("/login");
		}
		if(pathname === '/sysSetting') {
			navigate("/sysSetting/safeSet")
		}else if(pathname === '/deviceManage') {
			navigate("/deviceManage/collection")
		}
	}, [pathname]);
	useEffect(() => {
        ws.current?.close();
        if(accId) {
			const uri = `ws://${process.env.NODE_ENV === "development" ? SOCKET_DEV_HOST : window.location.host}${micro}/webSocket/acc/notice/${accId}`
            ws.current = new WebSocket(uri);
			ws.current.onopen = (e) => {
				heartCheck.reset();
				heartCheck.start();
			}
            ws.current.onmessage = (e) => {
                if(e.data && e.data != 'heart;;;') {
                    setTimeout(() => {
                        navigate('/login')
                        localStorage.removeItem('microtoken');
                        localStorage.removeItem('userDetail');
                        sessionStorage.clear();
                    }, 3000)
					heartCheck.reset();
					heartCheck.start();
                    
                }
            }
			ws.current.onclose = (e) => {
			};
        }
        

        return () => {
			ws.current?.close();
			heartCheck.reset();
		};
    }, [accId])
	return (
		<div className={location.pathname == "/login" || location.pathname == "/" ? "page-login" : "page-content"}>
			
			<AliveScope>
			{location.pathname == "/login" || location.pathname == "/" ? (
				""
			) : (
				<SysHeader />
			)}
			<div className="divide-menu" style={{ marginTop: 64 }}></div>
			<Routes>
				<Route path="/login" element={<Login />}></Route>
				<Route
					path="/realtimeMonitor"
					element={
						<React.Suspense fallback={<PageLoading />}>
							<RealtimeMonitor />
						</React.Suspense>
					}
				></Route>
				<Route
					path="/microAnalysis"
					element={
						<React.Suspense fallback={<PageLoading />}>
							<MicroAnalysis />
						</React.Suspense>
					}
				></Route>
				<Route
					path="/deviceManage"
					element={
						<React.Suspense fallback={<PageLoading />}>
							{/* <KeepAlive saveScrollPosition={false}> */}
							<DeviceManage />
							{/* </KeepAlive> */}
						</React.Suspense>
					}
				>
					<Route path="/deviceManage/collection" element={
								<React.Suspense>
									<AcquistionStation />
								</React.Suspense>
						}
					></Route>
					<Route path="/deviceManage/workspace" element={
							<React.Suspense>
								<Workspace />
							</React.Suspense>
						}
					></Route>
					
				</Route>
				<Route
					path="/dataStatistics"
					element={
						<React.Suspense fallback={<PageLoading />}>
							<DataStatistics />
						</React.Suspense>
					}
				></Route>
				<Route
					path="/sysSetting"
					element={
						<React.Suspense fallback={<PageLoading />}>
							<SysSetting />
						</React.Suspense>
					}
				>
					<Route path="/sysSetting/safeSet" element={
								<React.Suspense>
									<SafeSet />
								</React.Suspense>
						}
					></Route>
					<Route path="/sysSetting/waveFormSet" element={
							<React.Suspense>
								<WaveFormSet />
							</React.Suspense>
						}
					></Route>
					<Route path="/sysSetting/filterSet" element={
								<React.Suspense>
									<FilterSet />
								</React.Suspense>
						}
					></Route>
					<Route path="/sysSetting/algorithmSet" element={
							<React.Suspense>
								<AlgorithmSet />
							</React.Suspense>
						}
					></Route>
					<Route path="/sysSetting/platformAccess" element={
							<React.Suspense>
								<PlatformAccess />
							</React.Suspense>
						}
					></Route>
					<Route path="/sysSetting/accountManager" element={
							<React.Suspense>
								<AccountManager />
							</React.Suspense>
						}
					></Route>
					
				</Route>
			</Routes>
			{location.pathname === "/login" ? '' : <div className="middle-label">
				<span>{systemNameBottom}</span>
			</div>}
			</AliveScope>
		</div>
	);
}

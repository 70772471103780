/*
 * @desc: 系统信息统一配置文件
 * @Author: chenlin
 * @Date: 2021-08-10 10:17:52
 * @Last Modified by: ZhouYingren
 * @Last Modified by: chenlin
 * @LastEditTime: 2022-11-11 13:52:34
 */
import qs from "qs";

export const getToken = () => {
	// if (window.location.href.indexOf("microtoken") !== -1) {
	// 	const urlParam = qs.parse(window.location.href.replace("?", "&"));
	// 	let token = urlParam.token;
	// 	localStorage.setItem("microtoken", token);
	// 	return token;
	// }
	return localStorage.getItem("microtoken");
};

export const getParam = (param) => {
	let _param = localStorage.getItem(param);
	if (_param && _param !== "null" && _param !== "undefined") {
		return JSON.parse(_param);
	} else {
		return false;
	}
};

// 得到地图查询城市名称
//export const gisSearchConfig = () => localStorage.getItem("EmerGisSearchConfig") ? JSON.parse(localStorage.getItem("EmerGisSearchConfig")) : {}

// 获取地图信息
export const getMapInfo = () => getParam("planMapInfo");

//获取菜单列表
export const getMenuList = () => getParam("planMenuList") || [];

//获取菜单列表
export const getUserInfo = () => getParam("planUserInfo");

//获取角色信息
export const getRoleInfo = () => getParam("planRoleInfo");

//获取部门信息
export const getDutyInfo = () => getParam("planDutyInfo");

/**
 * 获取图片路径 带token
 */
export const getImgRoute = (src) => {
	return `${src}?token=${getToken()}`;
};

// 地图查询城市名称
export const gisSearchConfig = () => {
	return "";
};
/**
 * @description 判断管理员权限
 * @param {String} str 账号信息
*/
export const getPermission = (str) => {
const userDetail = JSON.parse(str)
if(JSON.stringify(userDetail.authorities).includes('管理员')) {
	return true
}else {
	return false
}
}
/*
 * @Author: SongXiao
 * @Date: 2021-01-29 17:28:36
 * @Last Modified by: ZhouYingren
 * @Last Modified Time: 2021-10-26 10:17:35
 * @Description: api路径
 */

import getServices from "./axios-request";
import { getToken } from "../config/constants";

const plan = "/scooper-user/";
export const micro = "/monitoring-web-api";
// export const micro = "/microseismic";

// export const SOCKET_DEV_HOST = '192.168.131.144:8899'
export const SOCKET_DEV_HOST = '192.168.130.190:8080'
// export const SOCKET_DEV_HOST = '192.168.128.222'

export let apis = {
	login: getServices(micro, () => getToken(), {
		doLogin: {
			url: "/api/login",
			type: "get",
			getResp: true
		},
		updatePassword: {
			url: "/api/changeCustos",
			type: "get",
			// noToken: true,
		},
		doLoginOut: { url: "/api/logout", type: "get" },
	}),

	// scooper-core
	scooperCore: getServices("/scooper-core-rest/data/", () => getToken(), {
		// 登录
		login: { url: "system/authManage/loginTo", type: "post", noToken: true },
		// 登出
		logout: { url: "system/authManage/logout", type: "post" },
		// 获取账号信息
		getAccountStatus: {
			url: "system/authManage/getAccountStatus",
			type: "post",
		},
		//获取角色信息
		getPmRoleDetail: { url: "system/permisions/pmRoleManage/getPmRoleDetail" },
		//获取部门信息
		getOrgDutyDetail: { url: "contacts/orgDutyManage/getOrgDutyDetail" },
		// 获取部门接口 树结构
		listTreeDeptByParent: {
			url: "contacts/orgDeptManage/listTreeDeptByParent",
			type: "post",
		},
		// 获取部门接口 平级
		listOrgDept: { url: "contacts/orgDeptManage/listOrgDept" },
		// 搜索部门下所有成员
		listOrgMember: {
			url: "contacts/orgMemberManage/listOrgMember",
			post: "get",
		},
		// 获取某部分下所有成员
		queryOrgMember: {
			url: "contacts/orgMemberManage/queryOrgMember",
			post: "get",
		},
	}),
	//microseismic
	microseismic: getServices(micro, () => getToken, {
		//工作面相关
		workingSpaceList: {
			url: "/workingSpace/list",
			type: "get",
		},
		//
		addWorkingSpace: {
			url: "/workingSpace/add",
			type: "postjson",
		},
		editWorkingSpace: {
			url: "/workingSpace/edit",
			type: "postjson",
		},
		workFaceAbandon: {
			url: "/workingSpace/abandon",
			type: "post",
		},
		checkIpAndMac: {
			url: "/deviceSub/checkIpAndMac",
			type: "get",
		},
		//采集站相关
		addDevice: {
			url: "/device/add",
			type: "postjson",
		},
		editDevice: {
			url: "/device/edit",
			type: "postjson",
		},
		startDevice: {
			url: "/device/updateState",
			type: "postjson",
		},
		getDeviceStatus: {
			url: "/device/getStatus/",
			type: "get",
		},
		//采集子站相关
		addDeviceSub: {
			url: "/deviceSub/add",
			type: "postjson",
		},
		editDeviceSub: {
			url: "/deviceSub/edit",
			type: "postjson",
		},
		//采集通道相关
		addBatchDeviceSubTunnel: {
			url: "/deviceSubTunnel/addBatch",
			type: "postjson",
		},
		getdeviceSubTunnelById: {
			url: "/deviceSubTunnel/getByDeviceSubId/",
			type: "get",
		},
		editdeviceSubTunnel: {
			url: "/deviceSubTunnel/editBatch",
			type: "postjson",
		},
		getSysDictItem: {
			url: "/sysDictItem/getDictItemByCode/",
			type: "get",
		},
		getDeviceType: {
			url: "/deviceType/list",
			type: "get",
		},
		//IP
		ipCheck: {
			url: "/api/ipCheck",
			type: "get",
		},
		//实时监测数据
		monitorWaveformData: {
			url: "/monitor/waveformData",
			type: "get",
		},
		monitorCheckExist: {
			url: "/monitor/checkExist",
			type: "get",
		},
		monitorHistoryFiles: {
			url: "/monitor/history/files",
			type: "get",
		},
		waveformDataByFilterAndTime: {
			url: "/monitor/waveformDataByFilterAndTime",
			type: "get",
		},
		systemFilterType: {
			url: "/system/filterType/list",
			type: "get",
		},
		systemFilterInfo: {
			url: "/system/filter/info",
			type: "get",
		},
		systemFilterSet: {
			url: "/system/filterSetting/type",
			type: "post",
		},
		systemFilterStatus: {
			url: "/system/filterSetting/status",
			type: "post",
		},
		waveformDataByFilter: {
			url: "/monitor/waveformDataByFilter",
			type: "get",
		},
		//微震分析数据
		eventPageList: {
			url: "/msEvent/pageList",
			type: "get",
		},
		eventById: {
			url: "/msEvent/getInfoById/",
			type: "get",
		},
		eventGetSpectrum: {
			url: "/msEvent/getSpectrum",
			type: "get",
		},
		eventExportListData: {
			url: "/msEvent/export/checked",
			type: "get",
		},
		eventExportALlListData: {
			url: "/msEvent/export/all",
			type: "get",
		},
		getInfoByIdAndFilter: {
			url: "/msEvent/getInfoByIdAndFilter",
			type: "get",
		},
		downloadEvent: {
			url: "/msEvent/download",
			type: "get",
		},
		errorCorrection: {
			url: "/msEvent/errorCorrection/event",
			type: "post",
		},
		// 时频分析
		getTimeFreqAnal: {
			url: "/msEvent/getTimeFreqAnal",
			type: "get",
		},
		// 累计能量
		getCumulativeEnergyData: {
			url: "/msEvent/getCumulativeEnergyData",
			type: "get",
		},
		// 磁盘空间调用接口
		getSystemInfo: {
			url: "/system/getSystemInfo",
			type: "get",
		},
		//数据统计接口
		yestdayMicro: {
			url: "/msEvendm/getYesterdayData",
			type: "get",
		},
		sevendayMicro: {
			url: "/msEvendm/getLastSevenDayData",
			type: "get",
		},
		onedayMicro: {
			url: "/msEvendm/getLastDayHoursData",
			type: "get",
		},
		AveMicroCount: {
			url: "/msEvendm/getFrequencyByDate",
			type: "get",
		},
		getTimeTrend: {
			url: "/msEvendm/getTimeTrend",
			type: "get",
		},
		getTrend: {
			url: "/msEvendm/getTrend",
			type: "get",
		},
		// 系统设置
		setSystemInfo: {
			url: "/system/setSystemInfo",
			type: "POST",
		},
		// 平台接入
		manageRegister: {
			url: "/manage/register",
			type: "postjson",
		},
		// 注册信息获取
		getRegisterInfo: {
			url: "/manage/getRegisterInfo",
			type: "get",
		},
		// 分页获取工作面
		getWorkingSpaceList: {
			url: "/workingSpace/pageList",
			type: "get",
		},
		// 分页获取工作面
		getDeviceList: {
			url: "/device/list",
			type: "get",
			getResp: true
		},
		// 采集站绑定工作面
		deviceBangding: {
			url: "/device/banding",
			type: "postjson",
		},
		// 采集站解除绑定工作面
		deviceUnbangding: {
			url: "/device/unbanding",
			type: "postjson",
		},
		// 绑定工作面列表
		getBandingList: {
			url: "/workingSpace/bandingList",
			type: "get",
		},
		// 废弃采集站
		deviceAbandon: {
			url: "/device/abandon",
			type: "post",
		},
		// 到时修改
		updateEvent: {
			url: "/msEvent/updateEvent",
			type: "post",
		},
		// 微震分析弹窗内获取上一个/ 下一个
		getPreviousOrNext: {
			url: "/msEvent/getPreviousOrNext",
			type: "get",
			getResp: true
		},
		// 初至线重置
		resetOffset: {
			url: "/msEvent/resetOffset",
			type: "post",
			getResp: true
		},
		getUsedSpace: {
			url: "/workingSpace/getUsedSpace",
			type: "get",
		},
		// 断开平台连接
		disconnect: {
			url: "/manage/disconnect",
			type: "post",
			getResp: true
		},
		getProgressBar: {
			url: "/msEvent/getProgressBar",
			type: "get",
			getResp: true
		},
	}),
};

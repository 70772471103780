/*
 * @Author: 吴慧彬 wuhuibin@scooper.com.cn
 * @Date: 2022-03-15 18:13:54
 * @LastEditTime: 2022-03-16 08:37:38
 * @LastEditors: Please set LastEditors
 * @Description: * @FilePath: \scooper-microseismic-web\src\store\reducer.js
 */
import { combineReducers } from "redux";
import MicroReducer from "./modules/micro";
// 模块化管理状态
let reducer = combineReducers({
	micro: MicroReducer,
});
export default reducer;

/*
 * @desc: 头部菜单
 * @Author: chenlin
 * @Date: 2021-12-24 14:39:35
 * @LastEditors: wangliang
 * @Last Modified by: chenlin
 * @LastEditTime: 2022-09-08 17:09:27
 */
import React, { useState, useEffect } from "react";
import {
	NavLink,
	Routes,
	Route,
	useNavigate,
	useLocation,
} from "react-router-dom";
import { Modal, Dropdown, Menu, Alert } from "antd";
import { UserOutlined, LogoutOutlined, SettingOutlined } from "@ant-design/icons";

import {
	systemNameTop,
	menuList,
	systemNameContent,
} from "../../config/config";
import { getMenuList, getToken, getUserInfo } from "../../config/constants";
import { apis } from "../../util/apis";
import HeaderTime from "./header-time";

import "./style/index.less";

const { confirm } = Modal;
const SysHeader = () => {
	const navigate = useNavigate();
	let [alertMessage, setAlertMessage] = useState();
	let [alertMessageState, setAlertMessageState] = useState();
	let [timeChangeTen, setTimeChangeTen] = useState(1);
    let [trueUsername, setTrueUsername] = useState('');
	let timer;
	useEffect(() => {
		apis.microseismic
			.getSystemInfo({ type: "monitor_space_notice" })
			.then((data) => {
				if (data.isWarn == true) {
					setAlertMessageState("error");
					setAlertMessage(data.message);
				} else {
					setAlertMessageState("success");
					setAlertMessage(data.message);
				}
			});

		try {
			let userDetail = localStorage.getItem('userDetail')
			setTrueUsername(JSON.parse(userDetail)?.trueUsername)
		}catch {

		}
}, []);
	useEffect(() => {
		timer = setInterval(() => {
			apis.microseismic
				.getSystemInfo({ type: "monitor_space_notice" })
				.then((data) => {
					if (data?.isWarn == true) {
						setAlertMessageState("error");
						setAlertMessage(data.message);
					} else {
						setAlertMessageState("success");
						setAlertMessage(data?.message);
					}
				});
			setTimeChangeTen(timeChangeTen + 10);
		}, 10000);

		return () => {
			clearInterval(timer);
		};
	}, [timeChangeTen]);
	/**
	 * 退出登录
	 */
	const exitSystem = async () => {
		let token = getToken();
		confirm({
			title: "确定要退出系统吗?",
			content: "",
			async onOk() {
				if (token) {
					await apis.login.doLoginOut({});
					localStorage.clear();
					sessionStorage.clear();
					navigate("/login");
				} else navigate("/login");
			},
			onCancel() {},
		});
	};

	const editPsd = () => {
		navigate("/sysSetting");
	}

	const setUserInfo = () => {
		return (
			<Menu style={{ marginLeft: -15 }}>
				<Menu.Item
                    key='name'
                    icon={<UserOutlined />}
                    style={{cursor: 'auto'}}
                >
                    {trueUsername}
                </Menu.Item>
                <Menu.Item
                    key='editPsd'
                    icon={<SettingOutlined />}
                    onClick={() => {
                        editPsd();
                    }}
                >
                    修改密码
                </Menu.Item>
				<Menu.Item
					key="loginout"
					icon={<LogoutOutlined />}
					onClick={() => {
						exitSystem();
					}}
				>
					退出登录
				</Menu.Item>
			</Menu>
		);
	};

	return (
		<>
			<div className="common-header">
				<div className="common-header-title">
					<i className="common-header-title-logo" />
					<span>{systemNameContent}</span>
				</div>
				<ul>
					{menuList.map((item, index) => {
						return (
							<li
								key={index}
								// onClick={() => {
								// 	menuSwitch(item.menuKey);
								// }}
							>
								<NavLink to={`${item.path}`}>{item.itemlist}</NavLink>
							</li>
						);
					})}
				</ul>
				<div className="alert">
					<Alert message={alertMessage} type={alertMessageState} />
				</div>
				{/* 右侧用户信息*/}
				<div className="common-header-right">
					<div className="icon-part">
						<Dropdown overlay={setUserInfo} trigger={["click"]}>
							<a onClick={(e) => e.preventDefault()}>
								<i className="right-admin" />
							</a>
						</Dropdown>
					</div>
					{/* 右侧日期信息 */}
					<HeaderTime />
				</div>
			</div>
		</>
	);
};
export default SysHeader;

/*
 * @Author: 吴慧彬 wuhuibin@scooper.com.cn
 * @Date: 2022-03-15 18:13:31
 * @LastEditTime: 2022-03-15 18:33:05
 * @LastEditors: Please set LastEditors
 * @Description: * @FilePath: \scooper-microseismic-web\src\store\index.js
 */
import { createStore, applyMiddleware, compose } from "redux";
// 引入模块化文件
import reducer from "./reducer";
import thunk from "redux-thunk";
// 这里是使用浏览器插件的配置
const composeFn = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
	: compose;
const enhancer = composeFn(applyMiddleware(thunk));
// 创建并到处仓库
const store = createStore(reducer, enhancer);
export default store;

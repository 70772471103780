/*
 * @Author: 吴慧彬 wuhuibin@scooper.com.cn
 * @Date: 2022-03-15 17:36:15
 * @LastEditTime: 2023-08-07 11:27:04
 * @LastEditors: wangliang
 * @FilePath: \monitoring-web-ui\src\view\login\index.jsx
 * @Description:登录页
 */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sha256_digest } from "../../lib/sha256/sha256";
import { UserOutlined, LockOutlined, UnlockFilled } from "@ant-design/icons";
import { Form, Input, Button, message } from "antd";
import { apis } from "../../util/apis";
import {systemNameTop, systemNameBottom, defaultMenu} from "../../config/config";
import "./style/login.less";

const FormItem = Form.Item;
export default function Login(props) {
	let navigate = useNavigate();
	const handleSubmit = (values) => {
		doLogin(values);
	};
	const doLogin = (values) => {
		let params = {
			username: values.username,
			custos: sha256_digest(values.password),
		};
		apis.login.doLogin(params).then(async res => {
			const data = res.data
			localStorage.setItem("microtoken", data.token);
			localStorage.setItem('userDetail',JSON.stringify(data.userDetail));
			sessionStorage.setItem('loginData',JSON.stringify(data.userDetail));
			if (res.messageKey === 'true') {
				navigate('/realtimeMonitor');
			}else {
				navigate(defaultMenu);
			}
		});
	};
	return (
		<div className="bg-container">
			<h1 className="system-title">
				{" "}
				<i className="system-title-logo"></i>
				{systemNameTop}
			</h1>
			<div className="login-content">
				<h2>用户登录</h2>
				<Form onFinish={handleSubmit} className="login-form">
					<FormItem
						validateFirst
						name="username"
						rules={[
							{ required: true, message: "请输入用户名！" },
							// { required: false, validator: checkContent },
						]}
					>
						<Input
							prefix={<UserOutlined />}
							placeholder="用户名"
							allowClear
						/>
					</FormItem>
					<FormItem
						validateFirst
						name="password"
						rules={[
							{ required: true, message: "请输入密码!" },
							// { required: false, validator: checkContent },
						]}
					>
						<Input
							prefix={<UnlockFilled />}
							placeholder="密码"
							type="password"
							allowClear
							onPaste={(e)=>{e.preventDefault()}}
						/>
					</FormItem>
					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							className="login-form-button"
						>
							登录
						</Button>
					</Form.Item>
				</Form>
			</div>
			<div className="login-sys-name-bottom">
				{systemNameBottom}
			</div>
		</div>
	);
}

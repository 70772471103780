/*
 * @Author: 吴慧彬 wuhuibin@scooper.com.cn
 * @Date: 2022-03-15 18:28:19
 * @LastEditTime: 2023-05-05 14:34:24
 * @LastEditors: wangliang
 * @FilePath: \monitoring-web-ui\src\store\modules\micro.js
 */
const defaultState = {
	workStationList: [
		{
			name: "",
			model: "",
			sampRrate: "",
			samplAccuracy: "",
			samplVoltage: "",
			subName: "",
			subIp: "",
		},
	],
	stationIndex: 0,
	workStationVisible: false,
	workFaceList: [],
	workFaceListCollect: [],
};
const MicroReducer = (state = defaultState, { type, value }) => {
	let initState = JSON.parse(JSON.stringify(state));
	switch (type) {
		case "addStation":
			initState.workStationList.push(value);
			initState = { ...initState };
			return initState;
		case "updateStationIndex":
			initState.stationIndex = value;
			initState = { ...initState };
			return initState;
		case "updateworkStationVisible":
			initState.workStationVisible = value;
			initState = { ...initState };
			return initState;
		default:
			return state;
	}
};
export default MicroReducer;

/*
 * @Author: 吴慧彬 wuhuibin@scooper.com.cn
 * @Date: 2022-03-15 17:06:35
 * @LastEditTime: 2023-08-15 11:12:27
 * @LastEditors: wangliang
 * @Description: * @FilePath: \scooper-microseismic-web\src\component\page-load.tsx
 */
import React from "react";
import { Spin } from "antd";
export default function pageload() {
	return (
		<div
			className="example"
			style={{
				margin: "20px 0",
				marginBottom: "20px",
				padding: "30px 50px",
				textAlign: "center",
				background: "#fff",
				borderRadius: "4px",
				height: 'calc(100vh - 84px)'
			}}
		>
			<Spin />
		</div>
	);
}

/*
 * @Author: 吴慧彬 wuhuibin@scooper.com.cn
 * @Date: 2022-04-26 09:32:06
 * @LastEditTime: 2022-04-26 09:32:06
 * @LastEditors: Please set LastEditors
 * @Description: * @FilePath: \monitoring-web-ui\src\component\sys-header\header-time.js
 */
/*
 * @desc: 头部时间组件
 * @Author: chenlin
 * @Date: 2021-12-28 15:55:53
 * @LastEditors: chenlin
 * @Last Modified by: chenlin
 * @LastEditTime: 2021-12-29 19:42:53
 */
import moment from "moment";
import React, { useEffect, useState } from "react";

let timeInterval = null;
let dateInterval = null;
function HeaderTime() {
	const [nowDate, getNowDate] = useState(null);
	const [week, getWeek] = useState(null);
	const [time, getTime] = useState(null);

	useEffect(() => {
		getToday();

		dateInterval = setInterval(() => {
			getToday();
		}, 60 * 1000);

		timeUpdate();

		return () => {
			timeInterval = null;
			dateInterval = null;
			clearInterval(timeInterval);
			clearInterval(dateInterval);
		};
	}, []);

	/**
	 * 当日时间及星期获取
	 */
	const getToday = () => {
		let now = moment().format("YYYY/MM/DD");
		let week = moment().format("dddd");
		getNowDate(now);
		getWeek(week);
	};

	/**
	 * 时间每秒刷新
	 */
	const timeUpdate = () => {
		timeInterval = setInterval(() => {
			let now = moment().format("YYYY-MM-DD HH:mm:ss");
			let time = now.substring(11, now.length);
			getTime(time);
		}, 1000);
	};

	return (
		<div className="time-info-part">
			{time && nowDate && (
				<>
					<h2>{time}</h2>
					<h4>
						{nowDate}
						<span>{week}</span>
					</h4>
				</>
			)}
		</div>
	);
}

export default HeaderTime;

/*
 * @Author: 吴慧彬 wuhuibin@scooper.com.cn
 * @Date: 2022-03-15 16:03:06
 * @LastEditTime: 2022-04-07 11:57:19
 * @LastEditors: Please set LastEditors
 * @Description: * @FilePath: \scooper-microseismic-web\src\index.js
 */
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/index";
import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import "moment/locale/zh-cn";
import IndexRouter from "./router/index";
import "antd/dist/antd.css";
import "./less/normalize.css";
import "./less/main.less";
import reportWebVitals from "./reportWebVitals";
import { AliveScope } from "react-activation";

ReactDOM.render(
	<React.StrictMode>
		{/* 使用Provider包裹，再把store传下去，那么就可以使用里面的数据了 */}
		<Provider store={store}>
			<ConfigProvider locale={zhCN}>
				<HashRouter>
					<AliveScope>
						<IndexRouter />
					</AliveScope>
				</HashRouter>
			</ConfigProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
